import { subject } from "@casl/ability";
import { AppAbility } from "@fonoa/permissions";
import {
  HelpIcon,
  ProductDashboardIcon,
  ProductInvoicingIcon,
  ProductLookupIcon,
  ProductReportingIcon,
  ProductReturnsIcon,
  ProductTaxIcon,
  SettingsIcon,
} from "@fonoa/ui-components/icons";
import { SidebarLinkRootType } from "@fonoa/ui-components/sidebar";
import { NextRouter } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import { useAuth, useTenant } from "@/hooks/auth";
import { useFeatureFlags } from "@/hooks/featureFlags/FeatureFlags";
import { useReturnsAuditTrailDialog } from "@/hooks/returns/useShouldShowAuditDialog";
import { useAbility } from "@/hooks/useAbility";
import { useIsProductActive, useIsSubproductActive } from "@/hooks/useIsProductActive";
import { Products, Subproducts } from "@/lib/product";
import { routes, RouteValueType } from "@/lib/routes";

const dashboardLinks: SidebarLinkRootType = {
  id: "dashboard",
  title: <FormattedMessage defaultMessage="Dashboard" id="hzSNj4" />,
  icon: ProductDashboardIcon,
  href: routes.dashboard,
  status: (pathname: string) => (pathname === routes.dashboard ? "Active" : "Default"),
  className: "mb-6",
};

function getHistoryHref(router: NextRouter) {
  if (router.pathname.includes("/tin-search")) {
    return router.pathname.includes("/single")
      ? routes.lookupTinSearchHistorySingle
      : routes.lookupTinSearchHistoryBatch;
  } else {
    return router.pathname.includes("/validation/single")
      ? routes.lookupValidationHistorySingle
      : routes.lookupValidationHistoryBatch;
  }
}

/**
 * It chooses Lookup sidebar link route based on the current URL and user permissions.
 * It tries to be smart - for example it's a link to TIN search batch validation if TIN search batch history is open,
 * or single TIN validation if single TIN validation history is open.
 * In case of no permissions to the expected page, it falls back to the default Lookup page.
 */
function getLookupHref({ pathname }: NextRouter, ability: AppAbility) {
  if (!pathname.includes("/lookup")) {
    return routes.lookup;
  }

  if (pathname.includes("/single")) {
    const canExecuteSingle = ability.can("execute", subject("single", { product: "lookup" }));
    if (pathname.includes("/tin-search")) {
      return canExecuteSingle ? routes.lookupTinSearchSingle : routes.lookup;
    } else {
      return canExecuteSingle ? routes.lookupValidationSingle : routes.lookup;
    }
  }

  const canExecuteBatch = ability.can("execute", subject("batch", { product: "lookup" }));
  if (pathname.includes("/tin-search")) {
    return canExecuteBatch ? routes.lookupTinSearchUpload : routes.lookup;
  } else {
    return canExecuteBatch ? routes.lookupValidationUpload : routes.lookup;
  }
}

const useLookupLinks = (): SidebarLinkRootType => {
  const isTinValidationActive = useIsProductActive(Products.LOOKUP);
  const isTinSearchActive = useIsSubproductActive(Subproducts.REVERSE_LOOKUP);

  const show = isTinValidationActive || isTinSearchActive;

  const ability = useAbility();
  const router = useRouter();

  return {
    id: "lookup",
    title: <FormattedMessage defaultMessage="Lookup" id="VzW9jr" />,
    icon: ProductLookupIcon,
    href: getLookupHref(router, ability),
    status: (pathname: string) =>
      (
        [
          routes.lookup,
          routes.lookupValidationSingle,
          routes.lookupValidationUpload,
          routes.lookupTinSearchSingle,
          routes.lookupTinSearchUpload,
        ] as RouteValueType[]
      ).includes(pathname as RouteValueType)
        ? "Active"
        : "Default",
    showChildren: (pathname) => pathname.startsWith(routes.lookup),
    children: [
      {
        id: "validation_history",
        title: <FormattedMessage defaultMessage="Result History" id="OHa4sM" />,
        status: (pathname) =>
          pathname.startsWith(routes.lookupValidationHistorySingle) ||
          pathname.startsWith(routes.lookupValidationHistoryBatch) ||
          pathname.startsWith(routes.lookupTinSearchHistorySingle) ||
          pathname.startsWith(routes.lookupTinSearchHistoryBatch)
            ? "Active"
            : "Default",
        href: getHistoryHref(router),
        show,
      },
      {
        id: "lookup_info",
        title: <FormattedMessage defaultMessage="TIN Validation Info" id="vZGKbg" />,
        status: (pathname) =>
          pathname.startsWith(routes.lookupInfoAvailability) ||
          pathname.startsWith(routes.lookupInfoSupportedData)
            ? "Active"
            : "Default",
        href: routes.lookupInfoSupportedData,
        className: "mb-2",
        show,
      },
    ],
  };
};

const useTaxLinks = (): SidebarLinkRootType => {
  const show = useIsProductActive(Products.TAX);
  const { taxDemo: isTaxDemoEnabled } = useFeatureFlags();

  return {
    id: "tax",
    title: <FormattedMessage defaultMessage="Tax" id="AwzkSM" />,
    icon: ProductTaxIcon,
    status: (pathname) =>
      pathname === routes.taxUSNexus || pathname === routes.taxGlobalThresholds
        ? "Active"
        : "Default",
    href: show ? routes.taxUSNexus : routes.tax,
    showChildren: (pathname) => pathname.startsWith(routes.tax),
    children: [
      {
        id: "external_scenarios",
        title: <FormattedMessage defaultMessage="External Scenarios" id="1N2L7L" />,
        status: (pathname) =>
          pathname.startsWith(routes.taxExternalScenarios) ? "Active" : "Default",
        href: routes.taxExternalScenarios,
        show: show && isTaxDemoEnabled,
      },
      {
        id: "tax_transactions",
        title: <FormattedMessage defaultMessage="Calculations" id="eE9icP" />,
        status: (pathname) => (pathname.startsWith(routes.taxCalculations) ? "Active" : "Default"),
        href: routes.taxCalculations,
        show,
      },
      {
        id: "tax_settings",
        title: <FormattedMessage defaultMessage="Tax Settings" id="KW7OnZ" />,
        status: (pathname) => (pathname.startsWith(routes.taxSettings) ? "Active" : "Default"),
        href: routes.taxSettingsRegistrations,
        show: show,
      },
    ],
  };
};

const useInvoicingLinks = (): SidebarLinkRootType => {
  const isInvoicingActive = useIsProductActive(Products.INVOICING);
  const isReportingActive = useIsProductActive(Products.REPORTING);

  const { invoicingTemplateConfigurator: areTemplatesEnabled } = useFeatureFlags();

  return {
    id: "invoicing",
    title: <FormattedMessage defaultMessage="Invoicing" id="ogLSJ3" />,
    icon: ProductInvoicingIcon,
    status: (pathname) =>
      pathname.startsWith(routes.invoicingOverview) || pathname === routes.invoicing
        ? "Active"
        : "Default",
    href: routes.invoicing,
    showChildren: (pathname) =>
      (pathname.startsWith(routes.invoicing) || pathname.startsWith(routes.einvoicing)) &&
      Boolean(isInvoicingActive) &&
      !isReportingActive,
    children: [
      {
        id: "invoicing_templates",
        title: <FormattedMessage defaultMessage="Templates" id="A3ptul" />,
        status: (pathname) =>
          pathname.startsWith(routes.invoicingTemplates) ? "Active" : "Default",
        href: routes.invoicingTemplates,
        className: "mb-2",
        show: isInvoicingActive && areTemplatesEnabled,
      },
    ],
  };
};

const useReportingLinks = (): SidebarLinkRootType => {
  const show = useIsProductActive(Products.REPORTING);

  return {
    id: "reporting",
    title: <FormattedMessage defaultMessage="Reporting" id="reFEEC" />,
    icon: ProductReportingIcon,
    status: (pathname) => (pathname === routes.reporting ? "Active" : "Default"),
    href: routes.reporting,
    showChildren: (pathname) =>
      (pathname.startsWith(routes.reporting) || pathname.startsWith(routes.einvoicing)) &&
      Boolean(show),
    children: [
      {
        id: "reporting_transactions",
        title: <FormattedMessage defaultMessage="Transactions" id="/jJLYy" />,
        status: (pathname) =>
          pathname.startsWith(routes.reportingTransactions) ? "Active" : "Default",
        href: routes.reportingTransactions,
        show,
      },
      {
        id: "reporting_companies",
        title: <FormattedMessage defaultMessage="Companies" id="3IInif" />,
        status: (pathname) =>
          pathname.startsWith(routes.reportingCompanies) ? "Active" : "Default",
        href: routes.reportingCompanies,
        show,
      },
    ],
  };
};

const useEInvoicingLinks = (): SidebarLinkRootType => {
  const isInvoicingActive = useIsProductActive(Products.INVOICING);
  const isReportingActive = useIsProductActive(Products.REPORTING);

  const show = isInvoicingActive || isReportingActive;

  return {
    id: "e-invoicing",
    title: <FormattedMessage defaultMessage="e-Invoicing" id="6BCwDp" />,
    icon: ProductReportingIcon,
    status: (pathname) => (pathname === routes.einvoicingTransactions ? "Active" : "Default"),
    href: routes.einvoicing,
    showChildren: (pathname) => pathname.startsWith(routes.einvoicing) && Boolean(show),
    children: [
      {
        id: "e_invoicing_transactions",
        title: <FormattedMessage defaultMessage="Transactions" id="/jJLYy" />,
        status: (pathname) =>
          pathname.startsWith(routes.einvoicingTransactions) ? "Active" : "Default",
        href: routes.einvoicingTransactions,
        show,
      },
      {
        id: "e_invoicing_companies",
        title: <FormattedMessage defaultMessage="Companies" id="3IInif" />,
        status: (pathname) =>
          pathname.startsWith(routes.einvoicingCompanies) ? "Active" : "Default",
        href: routes.einvoicingCompanies,
        show,
      },
      {
        id: "e_invoicing_data_import",
        title: <FormattedMessage defaultMessage="Import data" id="TkytCp" />,
        status: (pathname) =>
          pathname.startsWith(routes.einvoicingImportOverview) ? "Active" : "Default",
        href: routes.einvoicingImportOverview,
        show: show,
      },
      {
        id: "e_invoicing_documentation",
        title: <FormattedMessage defaultMessage="Documentation" id="isGKnz" />,
        status: (pathname) =>
          pathname.startsWith(routes.einvoicingDocumentationHomePage) ? "Active" : "Default",
        href: routes.einvoicingDocumentationHomePage,
        show: show,
      },
    ],
  };
};

const useReturnsLinks = (): SidebarLinkRootType | null => {
  const show = useIsProductActive(Products.RETURNS);
  const { auditTrailSidebarUrl } = useReturnsAuditTrailDialog();
  const ability = useAbility();
  const canViewAuditTrail = ability.can("request", subject("auditTrail", { product: "returns" }));

  return {
    id: "returns",
    title: <FormattedMessage defaultMessage="Returns" id="WuGkfJ" />,
    icon: ProductReturnsIcon,
    status: (pathname) => (pathname === routes.returns ? "Active" : "Default"),
    href: routes.returns,
    showChildren: (pathname) => pathname.startsWith(routes.returns),
    children: [
      {
        id: "returns_current_returns",
        title: <FormattedMessage defaultMessage="Current Returns" id="k0Swi1" />,
        status: (pathname) => (pathname === routes.returnsCurrentReturns ? "Active" : "Default"),
        href: routes.returnsCurrentReturns,
        show,
      },
      {
        id: "returns_filed_returns",
        title: <FormattedMessage defaultMessage="Filed Returns" id="QAmDAW" />,
        status: (pathname) => (pathname === routes.returnsFiledReturns ? "Active" : "Default"),
        href: routes.returnsFiledReturns,
        show,
      },
      {
        id: "returns_data_gathering",
        title: <FormattedMessage defaultMessage="Data Gathering" id="KmBknV" />,
        status: (pathname) =>
          pathname === routes.returnsDataGatheringSummary ? "Active" : "Default",
        href: routes.returnsDataGatheringSummary,
        show,
      },
      {
        id: "returns_workflow_templates",
        title: <FormattedMessage defaultMessage="Workflows by country" id="56oN9Z" />,
        status: (pathname) => (pathname === routes.returnsWorkflowTemplates ? "Active" : "Default"),
        href: routes.returnsWorkflowTemplates,
        show,
      },
      {
        id: "returns_audit_trail",
        title: <FormattedMessage defaultMessage="Audit Trail" id="nGe+pS" />,
        status: () => "Default",
        href: auditTrailSidebarUrl,
        show: show && canViewAuditTrail,
      },
    ],
  };
};

export const useSidebarLinks = (): SidebarLinkRootType[] => {
  const lookupLinks = useLookupLinks();
  const taxLinks = useTaxLinks();
  const invoicingLinks = useInvoicingLinks();
  const reportingLinks = useReportingLinks();
  const returnsLinks = useReturnsLinks();
  const ability = useAbility();
  const eInvoicingLinks = useEInvoicingLinks();
  const { data: tenant } = useTenant();
  const isReportingLegacyTenant = tenant?.isReportingLegacyTenant || false;

  // ---- temporary ----
  const auth = useAuth();
  const isUberPT = tenant?.tenantSlug == "uberportugal" && auth?.user?.email.endsWith("@uber.com");
  // ---- temporary ----

  const links = [
    ...(ability.can("view", subject("homepage", { product: "dashboard" })) ? [dashboardLinks] : []),
    ...(ability.can("view", subject("product", { product: "lookup" })) ? [lookupLinks] : []),
    ...(ability.can("view", subject("product", { product: "tax" })) ? [taxLinks] : []),
    ...(ability.can("view", subject("product", { product: "invoicing" })) && isReportingLegacyTenant
      ? [invoicingLinks]
      : []),
    ...(ability.can("view", subject("product", { product: "reporting" })) && isReportingLegacyTenant
      ? [reportingLinks]
      : []),
    ...(ability.can("view", subject("product", { product: "reporting" || "invoicing" })) &&
    !isReportingLegacyTenant
      ? [eInvoicingLinks]
      : []),
    ...(ability.can("view", subject("product", { product: "returns" })) && !isUberPT // temporary workaround to disable Returns for people from Uber but not for Fonoa Support accounts
      ? [returnsLinks]
      : []),
    {
      id: "settings",
      title: <FormattedMessage defaultMessage="Settings" id="D3idYv" />,
      icon: SettingsIcon,
      status: (pathname: string) => (pathname.startsWith(routes.settings) ? "Active" : "Default"),
      href: routes.settingsDetails,
      className: "mt-8",
    },
    {
      id: "help_center",
      title: <FormattedMessage defaultMessage="Help center" id="BYkLO/" />,
      icon: HelpIcon,
      status: () => "Default",
      href: "https://www.fonoa.com/help-center",
      external: true,
      externalIconProps: { className: "mr-6" },
    },
  ];

  return links.filter(Boolean) as SidebarLinkRootType[];
};
