import classNames from "classnames";
import { ReactNode } from "react";

interface LayoutProps {
  windowTitle?: string;
  children: ReactNode;
  Sidebar: JSX.Element;
  withPadding?: boolean;
  initialised?: boolean;
  HeaderBar?: JSX.Element;
  DemoDataLabel?: JSX.Element;
  NotificationsCenter?: JSX.Element;
  backgroundClass?: `bg-${string}`;
}

export function GenericLayout({
  children,
  HeaderBar,
  Sidebar,
  DemoDataLabel,
  NotificationsCenter,
  withPadding = true,
  initialised = true,
  backgroundClass = "bg-white",
}: LayoutProps) {
  return (
    <div className={classNames("h-[100vh]", backgroundClass)}>
      <div className="dark">{Sidebar}</div>
      <div className="relative flex h-full w-full flex-col pl-10 lg:pl-64">
        {DemoDataLabel}

        {HeaderBar}

        {initialised && (
          <div
            className={classNames("relative flex w-full flex-1 flex-col", {
              "px-6 pt-6 pb-9": withPadding,
            })}
          >
            {children}
          </div>
        )}

        {NotificationsCenter}
      </div>
    </div>
  );
}
